import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Layout>
      <Seo title="Page not found" />
      <article>
        <header>
          <h1>Qurve</h1>
        </header>
        <p>Page <span>Not</span> Found</p>
      </article>
    </Layout>
  </Layout>
)

export default NotFoundPage
